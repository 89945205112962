import React, {lazy} from 'react'
import Banner from "./components/banner/Banner"
// import Manufacturing from "./components/manufacture/Manufacturing"
// import Celebration from './pages/home/celebration/Celebration'
// import  Ppc from './pages/home/ppc/Ppc'
// import  Plus from './pages/home/plus/Plus'
// import  Xtreme from './pages/home/xtreme/Xtreme'
// import  Opc from './pages/home/opc/Opc'
// import  Trees from './pages/home/trees/Trees'
// import  Contact from './components/contact/Contact'
// import Scrolltop from './components/scrolltop/Scrolltop'
// import backimg from "./components/contact/contact-vector.png"
import Seo from "./components/seo/Seo"
const Manufacturing = lazy(() => import('./components/manufacture/Manufacturing'));
const Celebration = lazy(() => import('./pages/home/celebration/Celebration'));
const Ppc= lazy(() => import('./pages/home/ppc/Ppc'));
const Plus= lazy(() => import('./pages/home/plus/Plus'));
const Xtreme= lazy(() => import('./pages/home/xtreme/Xtreme'));
const Opc= lazy(() => import('./pages/home/opc/Opc'));
const Trees= lazy(() => import('./pages/home/trees/Trees'));
const Contact= lazy(() => import('./components/contact/Contact'));
const Scrolltop= lazy(() => import('./components/scrolltop/Scrolltop'));
// const backimg= lazy(() => import());
// const Seo= lazy(() => import());

const Allpages = () => {

  const pageURL = "https://www.wondercement.com/";
  return (
    <>
      <Seo 
      title="Wonder Cement: Bharat's Best Quality Cement Manufacturer | Most advanced Cement Manufacturer In Bharat"
      name="Wonder Cement"
      type="Web Site"
      description="Wonder Cement is Bharat's premier cement manufacturing company, delivering top-notch quality, by employing the most technologically advanced processes."
      pageURL={pageURL}
      />
      <Banner />
      <Manufacturing />
      <Celebration />
      <Ppc />
      <Plus />
      <Xtreme />
      <Opc /> 
      <Trees />
      <Contact text="Humse Judiye" spantext="Farq Nazar Aayega"
      name= "Full Name"
      num="Enter Mobile Number"
      message="Your Message"
      sub="Send message" 
      img="./images/contact-p.webp"
      />      
      <Scrolltop />
    </>
  )
}

export default Allpages
